/**
 * Created by amine on 24/08/2016.
 */

(function () {

    'use strict';

    class ConsultationBodyCtrl {
        constructor($element) {
            this.$element = $element;
        }

        static get $inject() {
            return ['$element'];
        }

        $onInit() {
            this.$element
                .addClass('layout-column')
                .addClass('flex-noshrink');
        }
    }

    module.exports = {
        bindings: {
            titles: '=',
            config: '<',
            consultation: '=ngModel',
            handleAutoSave: "&?autoSave",
            postSaveSubject: "<?",
            readonly: '<?',
            patient: "<"
        },
        controllerAs: "vm",
        controller: ConsultationBodyCtrl,
        template: require('consultation/views/consultation-body.tpl.html')
    };

})();