(function () {

    'use strict';

    class AsyncClick {
        constructor($timeout, $parse) {
            this.restrict = "A";
            this.$parse = $parse;
            this.$timeout = $timeout;
        }

        static create() {
            return new AsyncClick(...arguments);
        }

        link(scope, element, attrs) {
            const fn = this.$parse(attrs['asyncClick']);

            element.on('click', (event) => {
                element.prop('disabled', true);

                scope.$apply(() => {
                    const call = fn(scope, {$event: event});
                    if (this.isPromise(call)) call.finally(() => this.unDisable(element));
                    else this.$timeout(() => this.unDisable(element), 200);
                });
            });
        }

        unDisable(element) {
            element.prop('disabled', false);
        }

        isPromise(value) {
            return Boolean(value && typeof value.then === 'function');
        }
    }

    AsyncClick.create.$inject = ['$timeout', '$parse'];

    module.exports = AsyncClick.create;

})();