(function () {

    'use strict';

    class AuthController {
        constructor($auth, authService, $mdToast, $translate, $q, system) {
            this.$q = $q;
            this.$auth = $auth;
            this.toast = $mdToast;
            this.authService = authService;

            this.user = {};
            this.focus = false;

            this.licencedFor = system['licenced_for'];
            this.accounts = system['allowed_accounts'];

            this.simpleToast = $mdToast.simple()
                .textContent($translate.instant('log_error'))
                .position("bottom right")
                .hideDelay(1500);
        }

        static get $inject() {
            return ['$auth', 'authService', '$mdToast', '$translate', '$q', 'system'];
        }

        $onInit() {
            this.focus = true;
            this.currentYear = moment().format("YYYY");
        }

        submit() {
            const deferred = this.$q.defer();

            this.promise = this.$auth.login(this.user)
                .then((result) => this.success(result, deferred), () => this.error(deferred));

            return deferred.promise;
        }

        success(result, deferred) {
            if (_.isNil(result.data['account'])) this.error(deferred);
            else this.promise = this.authService.handleUser(result.data['account'])
                .then(deferred.resolve, deferred.reject);
        }

        error(deferred) {
            this.toast.show(this.simpleToast);
            deferred.reject(false);
        }

    }

    module.exports = AuthController;

})();
