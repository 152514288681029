/**
 * Created by BETALOS on 15/07/2016.
 */
(function () {
    'use strict';


    module.exports = authService;

    authService.$inject = [
        "$state", "$auth", "mnWebSocket", "dictionaryService", "configService", "$location", "$q", "mnTheme", "$http",
        "physicianService", "frontDeskService", "visitService", "dentalService", "notificationService", "blockService",
        "printService", "dcmService", "patientService"
    ];

    function authService(
        $state, $auth, mnWebSocket, dictionaryService, configService, $location, $q, mnTheme, $http, physicianService,
        frontDeskService, visitService, dentalService, notificationService, blockService, printService, dcmService,
        patientService
    ) {
        let self = this;

        self.token = null;
        self.user = null;
        self.staff = null;
        self.profile = null;

        self.lastState = null;

        self.getToken = getToken;
        self.setToken = setToken;
        self.unsetToken = unsetToken;

        self.handleUser = handleUser;
        self.authenticated = authenticated;

        self.logout = logout;
        self.aclHandler = aclHandler;
        self.handleRoute = handleRoute;
        self.isRootProfile = isRootProfile;
        self.hasSpecialPower = hasSpecialPower;
        self.getDefaultState = getDefaultState;

        self.getProfile = getProfile;
        self.getProfiles = getProfiles;
        self.handleProfile = handleProfile;
        self.handleProfilePartial = handleProfilePartial;

        self.handleUserAccount = handleUserAccount;

        //methods
        function getSuccessPromises() {
            const promises = [
                dictionaryService.getDictionary(),
                configService.getDefaultValues(),
                mnTheme.run(),
                frontDeskService.getRooms(),
                frontDeskService.getAgendas(),
                physicianService.list(),
                visitService.getSubLinksConfig(),
                notificationService.initialize(),
                blockService.getChoiceListFields(),
                printService.getDefaults(self.staff),
                dcmService.getIntegrationConfig(),
                patientService.getCustomFields(),
                patientService.getIdentifierConfig()
            ];

            if (configService.isDental()) promises.push(dentalService.getDentalProcedureGroups());

            return promises;
        }

        function handleUser(result) {
            const deferred = $q.defer();

            self.profile = _.chain(result.user).pick('profile').get('profile').value();
            self.user = _.omit(result.user, 'profile');
            self.staff = result.staff;

            self.token = $auth.getToken();

            localStorage.setItem("profile", JSON.stringify(self.profile));
            localStorage.setItem("user", JSON.stringify(self.user));
            localStorage.setItem("staff", JSON.stringify(self.staff));

            mnWebSocket.run()
                .then(success, error);

            function success() {
                $q.all(getSuccessPromises()).then(done, deferred.reject);
            }

            function done(data) {
                handleRoute(data);
                deferred.resolve(true);
            }

            function error() {
                logout();
                deferred.reject(true);
            }

            return deferred.promise;
        }

        function handleRoute(method) {
            const action = method === "target" ? method : 'go';
            const lastStateName = _.isNil(self.lastState) ? null : self.lastState.state().name;

            if (lastStateName) return $state[action](lastStateName, self.lastState.params(), {location: 'replace'});
            else {
                return $state[action](getDefaultState(), {}, {location: 'replace'});
            }
        }

        function getDefaultState() {
            return _.isNil(self.profile['default_state']) ? 'app.frontDesk.index' : self.profile['default_state'];
        }

        function authenticated() {
            self.token = $auth.getToken();

            self.user = JSON.parse(localStorage.getItem('user'));
            self.staff = JSON.parse(localStorage.getItem('staff'));
            self.profile = JSON.parse(localStorage.getItem('profile'));

            const deferred = $q.defer();

            mnWebSocket.run()
                .then(success, error);

            function success() {
                $q.all(getSuccessPromises())
                    .then(deferred.resolve, deferred.reject);
            }

            function error() {
                logout();
                deferred.reject(true);
            }

            return deferred.promise;
        }

        function aclHandler(acl) {
            if (isRootProfile()) return true;

            const action = _.get(acl, 'action');
            const isRoot = _.get(acl, 'is_root');
            const resource = _.get(acl, 'resource');

            if (isRoot) return isRootProfile();

            if (_.has(self.profile['permissions'], resource)) {
                const resourcePath = `permissions.${resource}`;

                if (_.get(self.profile, `${resourcePath}.is_all`, false)) return true;
                else if (_.get(self.profile, `${resourcePath}.actions.${action}`, 'none') !== 'none') return true;
            }

            return false;
        }

        function isRootProfile() {
            return self.profile['is_root'];
        }

        function hasSpecialPower() {
            return isRootProfile() || self.user['is_special'];
        }

        function logout() {
            $q.all([
                $auth.logout(), mnWebSocket.logout()
            ]).then(doneCallbacks);

            function doneCallbacks() {
                localStorage.clear();

                if (_.isNull($state.get('app'))) {
                    $location.path('/auth');
                    window.location.reload();
                } else $state.go('auth');
            }
        }

        function getProfiles() {
            const deferred = $q.defer();
            const url = "/api/profile/";

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getProfile(profile) {
            const deferred = $q.defer();
            const url = `/api/profile/${profile}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleProfile(profile) {
            const deferred = $q.defer();
            const isEdit = _.has(profile, 'id');
            const url = `/api/profile/${isEdit ? profile.id + '/' : ''}`;

            $http.post(url, profile)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleProfilePartial(profile) {
            const deferred = $q.defer();
            const isEdit = _.has(profile, 'id');
            const url = `/api/profile/${isEdit ? profile.id + '/' : ''}`;

            $http[isEdit ? 'put' : 'post'](url, profile)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleUserAccount(user) {
            const deferred = $q.defer();
            const update = _.has(user, 'id');
            const url = `/api/user/${update ? user.id + "/" : ''}`;

            if (!update) {
                user.password = user['new_password'];
                user = _.omit(user, 'new_password');
            }

            $http[update ? 'put' : 'post'](url, user)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getToken(refresh) {
            if (refresh || _.isNil(self.token)) return $auth.getToken();
            else return self.token
        }

        function setToken(token) {
            self.token = token;
            $auth.setToken(token);
        }

        function unsetToken(both) {
            self.token = null;
            if (!_.isNil(both)) $auth.removeToken();
        }

    }
})()
