(function () {
    'use strict';

    const getObjectId = require('shared/utils/get-object-id');
    const ENTRIES_ORDER_KEY = 'observation-entries-order';

    class ObservationCtrl {
        constructor($translate, moment, $q, observationService, $sce, system, $transition$, consultationService, listService, $scope, $state, configService, $element, textEditorService, $mdDialog) {
            this.$translate = $translate;
            this.moment = moment;
            this.$q = $q;
            this.observationService = observationService;
            this.configService = configService;
            this.$sce = $sce;
            this.dateFormat = system['date_format'].js;
            this.groupDateFormat = null;
            this.consultationService = consultationService;
            this.listService = listService;
            this.$state = $state;
            this.$element = $element;
            this.textEditorService = textEditorService;
            this.$scope = $scope;
            this.$mdDialog = $mdDialog;


            this.entryId = _.get($transition$.params('to'), 'entry');
            this.observationId = _.get($transition$.params('to'), 'observation');
            this.patientId = _.get($transition$.params('to'), 'pId');
            this.visitId = _.get($transition$.params('to'), 'visitId');
            this.lockSubscription = this.consultationService.lockSubject.subscribe(data => {
                this.isLocked = data.lock;
                $scope.$applyAsync();
            });

            this.uploading = false;
            this.entryTypes = [];
            this.bookmarkedEntryTypes = [];
            this.othersEntryTypes = [];
            this.config = null;
            this.entry = {};
            this.observation = {
                id: null,
                patient: this.patientId
            };
            this.observations = [];
            this.currentVersion = -1;
            // this.patient = null;
            this.activeMenu = false;
            this.showFiles = false;
            this.uploadContext = {entry: null, observation: this.observationId};
            this.extraContext = {};
            this.upload = () => Promise.resolve(false);


            this.order = localStorage.getItem(ENTRIES_ORDER_KEY) || "asc";
            this.showMedicalFile = false;
            this.filterEntryTypes = [];
            this.filteredTypes = [];

            this.editorOpts = {
                "auto_compile": true,
                "no_config_key": true,
                "has_misc_menu": true,
                "patient": this.patientId,
                "has_patient_menu": true,
                "has_physician_menu": true,
                "has_measurement_menu": true,
                onInit: (editor) => {
                    this.editor = editor;
                    setTimeout(() => this.editor.focus(), 500);
                }
            }
        }

        static get $inject() {
            return ["$translate", "moment", "$q", "observationService", "$sce", "system", "$transition$", "consultationService", "listService", "$scope", "$state", "configService", "$element", "textEditorService", "$mdDialog"];
        }

        $onInit() {

            this.$q.all([
                this.configService.get("observation_config"),
                this.listService.list("ObservationEntryType"),
                this.observationService.get(this.patientId),
                //this.observationService.observationSummary(this.patientId)
            ]).then(data => {
                this.config = data[0] || {};
                this.groupDateFormat = _.get(this.config, 'group_date_format', this.dateFormat);
                this.extraContext = _.get(this.config, 'use_file_patient_context') ? {patient: this.patientId} : {};

                this.handleEntryTypes(data[1]);

                this.observations = _.reduce(data[2], (result, observation) => {
                    observation = this.prepareObservation(observation);

                    return _.concat(result, observation);
                }, []);

                this.setCurrentObservation();
                this.newEntry();
            }, _.noop);
        }

        $onDestroy() {
            this.lockSubscription.unsubscribe();
        }

        newEntry(type = null, activeMenu = false, default_content = "") {
            this.entry = _.assign({}, {
                transaction_uid: getObjectId(),
                entry_date: new Date(),
                content: default_content,
                type: _.isNil(type) ? {id: _.get(this.config, "default_type")} : type
            });

            this.setUploadContext();
            this.activeMenu = activeMenu;
            if (this.editor) setTimeout(() => this.editor.focus(), 0);
        }

        selectEntryType(entryType = null, activeMenu = false) {
            if (this.config.reports_redirect && entryType.cat === "report") {
                if (!!this.observationId) {
                    this.redirectToReport(entryType);
                } else {
                    this.observationService.saveObservation(this.observation)
                        .then((data) => {
                            this.observationId = data.id;
                            this.redirectToReport(entryType);
                        }, _.noop);
                }
            } else {
                if (_.isNil(this.entry.id)) {
                    this.newEntry(entryType, activeMenu)
                } else {
                    this.entry.type = _.isNil(type) ? {id: _.get(this.config, "default_type")} : type;
                    this.activeMenu = activeMenu;
                }
            }

        }

        redirectToReport(entryType) {
            return this.observationService.reportFormState(entryType, _.get(this.entry, "report.id"), this.observationId)
        }

        selectMedicalPrescription(id = null, copy = false) {
            const success = () => {
                this.observationService.medicalPrescriptionState(
                    copy ? null : id,
                    this.observationId,
                    copy ? id : null
                );
            }

            if (!!this.observationId) {
                success();
            } else {
                this.observationService.saveObservation(this.observation)
                    .then((data) => {
                        this.observationId = data.id;
                        success();
                    }, _.noop);
            }
        }

        selectReport(entry, copy = null, model = null) {
            this.observationService.reportFormState(
                entry.type,
                copy ? null : entry.report.id,
                _.isNil(this.observationId) ? -1 : this.observationId,
                model,
                copy ? entry.report.id : null
            );
        }

        lock() {
            this.consultationService.lockSubject.next({lock: !this.isLocked});
        }

        submit() {
            if (!this.transactionUID) this.transactionUID = getObjectId();

            const newEntry = _.chain(this.entry).get("id").isNil().value();
            const entryIndex = _.chain(this.entry).get("_index", -1).value();
            const newObservation = _.chain(this.observation).get("id").isNil().value();
            const entry = _.assign({}, this.entry, {
                observation: _.pick(this.observation, ['id', 'comment', 'indication', 'patient'])
            });

            return this.observationService
                .saveEntry(entry, this.visitId, this.patientId)
                .then(data => {
                    console.log(data);
                    this.submitSuccess(data, newEntry, entryIndex, newObservation)
                });
        }

        submitSuccess(data, newEntry, entryIndex, newObservation, upload = true) {
            const deferred = this.$q.defer();
            this.reloadState(data.id, data.observation.id)
                .then(() => {
                    this.setUploadContext(this.entryId).then(() => {
                        if (upload) this.afterSave(data, newEntry, entryIndex, newObservation);
                        //else this.newEntry();
                        deferred.resolve(this.entryId);
                    });
                });

            return deferred.promise;
        }

        afterSave(data, newEntry, entryIndex, newObservation) {
            this.upload(this.uploadContext).then(() => {
                this.afterUpload(data, newEntry, entryIndex, newObservation)
            });
        }

        afterUpload(data, newEntry, entryIndex, newObservation) {
            if (newObservation) {
                this.reloadObservation(this.observationId);
                this.observationService.newItemSubscription.next([this.observationId, this.entryId]);
            } else {
                this.refreshEntry(data, newEntry, entryIndex);
            }

            this.uploading = false;
            this.newEntry();
        }

        preUpload() {
            const deferred = this.$q.defer();

            if (this.uploading) deferred.reject(false);

            this.uploading = true;
            const newEntry = _.chain(this.entry).get("id").isNil().value();
            const entryIndex = _.chain(this.entry).get("_index", -1).value();
            const newObservation = _.chain(this.observation).get("id").isNil().value();
            const entryId = this.entryId;
            const entry = _.assign({}, this.entry, {
                observation: _.pick(this.observation, ['id', 'comment', 'indication', 'patient'])
            });

            if (_.isEmpty(entry.content)) this.entry.content = "N.C.";

            if (newEntry) {
                this.observationService.saveEntry(entry, this.visitId, this.patientId).then(data => {
                    this.submitSuccess(data, newEntry, entryIndex, newObservation, false)
                        .then(() => {
                            this.afterSave(data, newEntry, entryIndex, newObservation);
                            deferred.resolve([]);
                        });
                })
            } else {
                deferred.resolve(null);
            }

            return deferred.promise;
        }

        prepareObservation(observation) {
            observation.entries.map((entry, key) => {
                entry.trustedContent = this.$sce.trustAsHtml(entry.content);
                entry._index = key;
            });

            return observation;
        }

        reloadObservation(id) {
            this.observationService.getObservation(id)
                .then(data => {
                    this.observation = this.prepareObservation(data);
                    if (_.isNil(this.observations[this.currentVersion]) || this.currentVersion === -1) {
                        this.observations.push(this.observation);
                    } else {
                        this.observations[this.currentVersion] = this.observation;
                    }

                    this.setCurrentObservation();
                })
        }

        refreshEntry(entry, newEntry, entryIndex) {
            entry.trustedContent = this.$sce.trustAsHtml(entry.content);

            _.pushOrUpdate(this.observation.entries, entry);
            if (entryIndex === -1) {
                this.observationService.newItemSubscription.next([this.observationId, this.entryId]);
            }

            this.orderedEntries = this.orderEntries();
        }

        setCurrentObservation() {
            this.currentVersion = _.findIndex(this.observations, obs => {
                return this.observationId === obs.id || _.includes(_.map(obs.entries, 'id'), this.entryId);
            });

            this.observation = this.observations[this.currentVersion] || {
                id: null,
                patient: this.patientId,
                indication: {id: _.get(this.config, "default_indication", null)},
                entries: []
            };

            this.confirmCurrentObservation();
        }

        confirmCurrentObservation() {
            this.reloadState(this.entryId, this.observation.id);

            this.orderedEntries = this.orderEntries();
            this.observationService.currentSubscription.next([this.observation.id, this.entryId]);

            setTimeout(() => {
                this.scrollToEntry();
            }, 500);
        }

        observationSelected() {
            if (this.currentVersion === -1) this.newObservation();
            else this.observation = this.observations[this.currentVersion];

            this.confirmCurrentObservation();
        }

        orderEntries() {
            return _.chain(this.observation.entries)
                .orderBy(
                    [
                        entry => {
                            const _m = this.moment(entry.entry_date, this.dateFormat);

                            return _m.unix();
                        },
                        entry => {
                            return entry.entry_creation_time ? entry.entry_creation_time : "00:00";
                        }
                    ], [
                        this.order,
                        "asc"
                    ]
                )
                .filter(entry => {
                    switch (true) {
                        case entry['is_medical_prescription']:
                            return !this.filteredTypes.includes(-1);
                        default:
                            return !!entry.type && !this.filteredTypes.includes(entry.type.id);
                    }
                })
                .groupBy('entry_date')
                .value();
        }

        toggleFilter(item = null) {
            if (_.isNull(item)) {
                if (_.isEmpty(this.filteredTypes)) this.filteredTypes = _.map(this.filterEntryTypes, item => item.id);
                else this.filteredTypes = [];
            } else {
                if (this.filteredTypes.includes(item.id)) this.filteredTypes = _.without(this.filteredTypes, item.id);
                else this.filteredTypes.push(item.id);
            }

            this.orderedEntries = this.orderEntries();
        }

        isShown(item = null) {
            if (_.isNull(item)) {
                return _.isEmpty(this.filteredTypes);
            } else {
                return !this.filteredTypes.includes(item.id)
            }
        }

        editEntry(entry) {
            switch (true) {
                case entry['is_medical_prescription']:
                    this.selectMedicalPrescription(entry.prescription.id)
                    break;
                case entry['is_report'] && this.config['reports_redirect']:
                    this.selectReport(entry);
                    break;
                default:
                    this.entry = _.assign({}, entry);
                    this.setUploadContext(this.entry.id);

                    this.activeMenu = !_.get(this.config, `bookmarked_types.${entry.type.id}`, false);
            }
        }

        deleteObservation($event) {
            const confirm = this.$mdDialog.confirm()
                .title(this.$translate['instant']("observation_remove_confirm_title"))
                .textContent(this.$translate['instant']("observation_remove_confirm_text"))
                .ariaLabel('confirm')
                .targetEvent($event)
                .ok(this.$translate['instant']('confirm_ok'))
                .cancel(this.$translate['instant']('confirm_cancel'));

            this.$mdDialog.show(confirm).then(() => {
                this.observationService
                    .deleteObservation(this.observation)
                    .then(() => {
                        this.reloadState().then(this.$state.reload);
                        this.observationService.currentSubscription.next([this.observation.id, this.entryId]);
                    })
            });
        }

        deleteEntry(entry) {
            const stateSuccess = () => {
                this.reloadObservation(this.observation.id);

                this.observationService.newItemSubscription.next([this.observationId, null])
            };

            const deleteSuccess = () => {
                if (entry.id === this.entryId) {
                    this.reloadState(null, this.observation.id)
                        .then(stateSuccess);
                } else {
                    stateSuccess();
                }
            };

            const confirm = this.$mdDialog.confirm()
                .title(this.$translate['instant']("observation_entry_remove_confirm_title"))
                .textContent(this.$translate['instant']("observation_entry_remove_confirm_text"))
                .ariaLabel('confirm')
                .targetEvent(null)
                .ok(this.$translate['instant']('confirm_ok'))
                .cancel(this.$translate['instant']('confirm_cancel'));

            this.$mdDialog.show(confirm).then(() => {
                this.observationService.deleteEntry(entry)
                    .then(deleteSuccess, _.noop);
            });
        }

        duplicateEntry(entry) {
            switch (true) {
                case entry['is_medical_prescription']:
                    this.selectMedicalPrescription(entry.prescription.id, true);
                    break;
                case entry['is_report'] && this.config['reports_redirect']:
                    this.selectReport(entry, true);
                    break;
                default:
                    const type = _.cloneDeep(entry.type);
                    const activeMenu = _.find(this.othersEntryTypes, ['id', type.id]) > -1;
                    this.newEntry(type, activeMenu, entry.content)
                    return false;
            }
        }

        validationObservation(validate) {
            this.observation.is_validated = validate;
            this.observation.validate_at = validate ? this.moment().format(this.dateFormat) : null;

            return this.observationService.updateObservation(this.observation);
        }

        validateObservation() {
            return this.validationObservation(true);
        }

        invalidateObservation() {
            return this.validationObservation(false);
        }

        toggleOrder() {
            if (this.order === "asc") this.order = "desc";
            else this.order = "asc";

            localStorage.setItem(ENTRIES_ORDER_KEY, this.order);
            this.orderedEntries = this.orderEntries();
        }

        toggleMedicalFile() {
            this.showMedicalFile = !this.showMedicalFile;
            this.showFiles = false;
        }

        toggleFiles() {
            this.showFiles = !this.showFiles;
            this.showMedicalFile = false;
        }

        handleEntryTypes(types) {
            let additionalTypes = []
            this.entryTypes = types;
            this.entryTypes.forEach((type) => {
                const is_disabled = _.get(this.config, `disabled_types.${type.id}`, false) || (!this.config.use_reports && type.cat === 'report');
                const is_bookmarked = _.get(this.config, `bookmarked_types.${type.id}`, false);

                if (!is_disabled) {
                    if (is_bookmarked) this.bookmarkedEntryTypes.push(type);
                    else this.othersEntryTypes.push(type);
                }


            });

            if (_.get(this.config, "use_medical_prescription", false)) {
                additionalTypes.push({
                    id: -1,
                    value: this.$translate.instant("new_medical_prescription")
                })
            }

            this.filterEntryTypes = _.concat(this.bookmarkedEntryTypes, additionalTypes, this.othersEntryTypes);
        }

        scrollToEntry() {
            const element = $("md-card-content", this.$element);
            const elementTop = element.scrollTop();
            const scrollTo = pos => {
                element.stop().animate({scrollTop: elementTop + pos}, {
                    duration: 600
                });
            };

            let entryElement = $("[entry]", element).last();

            if (!_.isNil(this.entryId)) {
                entryElement = $(`[entry=${this.entryId}]`, element)
            }

            scrollTo(_.get(entryElement.parent(".entries-group").position(), "top", 0) - 10);
        }

        get invalidForm() {
            return _.isNil(this.entry.type) || _.isEmpty(this.entry.content) || _.isNil(this.entry.entry_date) || _.isNil(this.observation.indication)
        }

        setUploadContext(entry = null, observation = this.observationId) {
            const deferred = this.$q.defer();

            this.$scope.$applyAsync(() => {
                this.uploadContext = {entry, observation};
                setTimeout(() => deferred.resolve(null));
            });

            return deferred.promise;
        }

        reloadState(entryId = null, observationId = null) {
            if (_.isNil(observationId)) entryId = null;

            this.entryId = entryId;
            this.observationId = observationId;

            return this.$state.go("app.visit.consultation.observation", {
                entry: this.entryId,
                observation: this.observationId,
            }, {location: 'replace'});
        }
    }

    module.exports = ObservationCtrl;

})();
