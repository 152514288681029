/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    const angular = require('angular');


    const ConsultationListCtrl = require("./controllers/ConsultationListCtrl");
    const ConsultationBodyCtrl = require("./controllers/ConsultationBodyCtrl");
    const MobileConsultationCtrl = require("./controllers/MobileConsultationCtrl");
    const ObservationCtrl = require("./controllers/ObservationCtrl");

    const mnConsultationBody = require("./components/consultation-body");

    const consultationService = require("./services/consultationService");

    angular
        .module("medinet")

        .controller('ConsultationListCtrl', ConsultationListCtrl)
        .controller('ConsultationBodyCtrl', ConsultationBodyCtrl)
        .controller('MobileConsultationCtrl', MobileConsultationCtrl)
        .controller('ObservationCtrl', ObservationCtrl)

        .component('mnConsultationBody', mnConsultationBody)

        .service("consultationService", consultationService);


})();