(function () {

    'use strict';

    class StaffDialogCtrl {
        constructor($mdDialog, actorService) {
            this.dialog = $mdDialog;
            this.actorService = actorService;
        }

        static get $inject() {
            return ["$mdDialog", "actorService"];
        }

        $onInit() {
            this.staff = this.staff ? this.staff : {users: [{}], is_active: true};
            let phone_numbers_value = _.get(this.staff, "contact_info.phone_numbers", []);
            this.staff.contact_info = _.assign(this.staff.contact_info, {phone_numbers: phone_numbers_value});
        }

        cancel() {
            this.dialog.cancel();
        }

        addUser() {
            let newUser = {
                is_special: this.staff.users.length === 1
            };

            this.staff.users.push(newUser);
        }

        deleteUser(index) {
            this.staff.users.splice(index, 1);
        }

        submit() {
            this.promise = this.actorService.handleStaff(this.staff)
                .then(data => this.dialog.hide(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: StaffDialogCtrl,
        template: require("../views/staff-dialog.tpl.html"),
    };

})();
