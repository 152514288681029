/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    const angular = require('angular');

    const authService = require("./services/authService");

    // moved to es6
    const logout = require("./components/logout");
    const ActorService = require('./services/actorService');
    const AuthController = require("./controllers/AuthController");

    const mnAcl = require("./directives/acl");
    const mnAclFilter = require("./filters/acl");
    const mnSpecialPower = require("./directives/special-power");
    const passwordConfirm = require("./directives/password-confirm");


    angular
        .module("medinet")

        .service("authService", authService)
        .service('actorService', ActorService)

        .controller('AuthController', AuthController)

        .directive("mnAcl", mnAcl)
        .directive("mnSpecialPower", mnSpecialPower)
        .directive("passwordConfirm", passwordConfirm)

        .component("mnLogout", logout)

        .filter("mnAcl", mnAclFilter);

})();