/**
 * Created by BETALOS on 26/04/2016.
 */
(function () {

    'use strict';

    const {Subject} = require("rxjs");

    class ModalCtrl {
        constructor($mdDialog) {
            this.search = {};
            this.close = $mdDialog.cancel;
            this.selectRow = $mdDialog.hide;
        }

        static get $inject() {
            return ["$mdDialog"];
        }

        clearQuery() {
            // just a hack !!!!
            this.search = _.omitBy(this.search, v => _.isString(v));
        }
    }

    class ModalService {
        constructor($q, $rootScope, $compile, $controller, $mdDialog) {
            this.$q = $q;
            this.dialog = $mdDialog;
            this.$compile = $compile;
            this.$rootScope = $rootScope;
            this.$controller = $controller;

            this.subject = new Subject();
            this.modals = {
                patient_modal: {
                    ctrl: null,
                    instantiated: false,
                    template: require("patient/views/patient-search-dialog.tpl.html"),
                }
            }
        }

        static get $inject() {
            return ["$q", "$rootScope", "$compile", "$controller", "$mdDialog"];
        }

        showModal(modal_id, query, component_id, ev) {
            if (this.modals[modal_id].instantiated) this.openDialog(modal_id, query, component_id, ev);

            else this.instantiateModel(modal_id, this.modals[modal_id].template).then(resolved => {
                this.modals[modal_id].instantiated = true;
                this.modals[modal_id].ctrl = resolved.ctrl;

                this.openDialog(modal_id, query, component_id, ev);
            }, _.noop);
        }

        openDialog(element, query, component_id, ev) {
            this.modals[element].ctrl.search = _.isNil(query) ? {} : query;

            this.dialog.show({
                multiple: true,
                targetEvent: ev,
                focusOnOpen: false,
                parent: $(document.body),
                clickOutsideToClose: false,
                contentElement: `#${element}`,
            }).then(data => this.success(data, component_id, element), _.noop);
        }

        success(data, component_id, modal_id) {
            this.subject.next({
                data: data,
                subject: `${component_id}.${modal_id}`
            });
        }

        instantiateModel(modal_id, template) {
            const deferred = this.$q.defer();

            if (_.isEmpty(template)) deferred.reject("no template were found");

            const linkFn = this.$compile(template);
            const modalScope = this.$rootScope.$new();

            let modalElement = linkFn(modalScope);
            let controllerObjBefore = modalScope["vm"];

            const input = {
                $scope: modalScope
            };

            let modalController = _.assign(
                this.$controller(ModalCtrl, input, false, "vm"), controllerObjBefore
            );

            $(document.body).append(modalElement);

            deferred.resolve({
                element: modalElement,
                ctrl: modalController,
            });

            return deferred.promise;
        }
    }

    module.exports = ModalService;

})();
